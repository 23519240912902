const apiUrl = (`${process.env.REACT_APP_BASE_URL}/yuni/responses`);
;

export const fetchResponses = async () => {
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch responses');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching responses:', error);
    throw error;
  }
};

export const updateCheckedStatus = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/yuni/responses/${id}/checked`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to update checked status');
    }

    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error updating checked status:', error);
    throw error;
  }
};