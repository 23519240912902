import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Table from '../../components/common-ui/Table/table';

const QuestionTable = ({ questionsData, storiesData, onUpdate }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [error, setError] = useState(''); // State for error handling

  const getStoryName = (storyId) => {
    const matchedStory = storiesData.find((story) => story._id === storyId);
    return matchedStory ? matchedStory.storyName : storyId;
  };

  const handleEditClick = (question) => {
    // Ensure wrongAnswers is an array of exactly 2 items, if not, default to empty strings
    const wrongAnswers = question.wrongAnswers.length === 2 ? question.wrongAnswers : ['', ''];

    setCurrentQuestion({
      ...question,
      wrongAnswers: wrongAnswers // Ensure there are exactly 2 wrong answers
    });
    setError(''); // Reset any previous errors
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentQuestion(null);
    setError(''); // Clear errors when closing modal
  };

  const handleSaveChanges = () => {
    // Validation for MCQ: Ensure both wrong answers are provided
    if (currentQuestion.questionType === 'MCQ' && (currentQuestion.wrongAnswers[0] === '' || currentQuestion.wrongAnswers[1] === '')) {
      setError('Please provide exactly 2 wrong answers.');
      return;
    }

    if (currentQuestion) {
      // No need to modify wrongAnswers; save it as an array
      onUpdate(currentQuestion); // Pass the updated data back to the parent component
      setShowModal(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentQuestion({ ...currentQuestion, [name]: value });
  };

  const handleWrongAnswerChange = (index, value) => {
    const updatedWrongAnswers = [...currentQuestion.wrongAnswers];
    updatedWrongAnswers[index] = value;
    setCurrentQuestion({ ...currentQuestion, wrongAnswers: updatedWrongAnswers });
  };

  // Map questionsData to match the structure expected by Table component
  const tableData = questionsData.map((question) => ([
    getStoryName(question.storyId),
    question.subject,
    question.question,
    question.correctAnswer,
    <div>{question.wrongAnswers.map((answer, index) => <div key={index}>{answer}</div>)}</div>,
    question.questionType,
    question.difficulty,
    <button onClick={() => handleEditClick(question)} className="text-blue-500 underline">
      <FontAwesomeIcon icon={faEdit} /> {/* Replace text with edit icon */}
    </button>
  ]));

  // Define table headers with new Edit column
  const headers = ['Story Name', 'Subject', 'Question', 'Correct Answer', 'Wrong Answers', 'Question Type', 'Difficulty', 'Edit'];

  return (
    <div>
      <Table headers={headers} data={tableData} />

      {/* Modal implementation */}
      {showModal && currentQuestion && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
            <h2 className="text-xl mb-4">Edit Question</h2>
            <form>
              {/* Subject Dropdown */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Subject</label>
                <select
                  name="subject"
                  value={currentQuestion.subject}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded w-full"
                >
                  <option value="English">English</option>
                  <option value="Maths">Maths</option>
                  <option value="Chinese">Chinese</option>
                </select>
              </div>

              {/* Question Input */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Question</label>
                <input
                  type="text"
                  name="question"
                  value={currentQuestion.question}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded w-full"
                />
              </div>

              {/* Question Type Dropdown */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Question Type</label>
                <select
                  name="questionType"
                  value={currentQuestion.questionType}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded w-full"
                >
                  <option value="Open-ended">Open-ended</option>
                  <option value="MCQ">MCQ</option>
                </select>
              </div>

              {/* Conditional Correct Answer and Wrong Answers for MCQ */}
              {currentQuestion.questionType === 'MCQ' && (
                <>
                  {/* Correct Answer Input */}
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Correct Answer</label>
                    <input
                      type="text"
                      name="correctAnswer"
                      value={currentQuestion.correctAnswer}
                      onChange={handleInputChange}
                      className="border border-gray-300 p-2 rounded w-full"
                    />
                  </div>

                  {/* Wrong Answers Inputs (2 fields) */}
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Wrong Answers</label>
                    <input
                      type="text"
                      name="wrongAnswer1"
                      value={currentQuestion.wrongAnswers[0] || ''}
                      onChange={(e) => handleWrongAnswerChange(0, e.target.value)}
                      className="border border-gray-300 p-2 rounded w-full mb-2"
                    />
                    <input
                      type="text"
                      name="wrongAnswer2"
                      value={currentQuestion.wrongAnswers[1] || ''}
                      onChange={(e) => handleWrongAnswerChange(1, e.target.value)}
                      className="border border-gray-300 p-2 rounded w-full"
                    />
                  </div>

                  {/* Display error if both wrong answers are not provided */}
                  {error && <div className="text-red-500 mb-4">{error}</div>}
                </>
              )}

              {/* Difficulty Dropdown */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Difficulty</label>
                <select
                  name="difficulty"
                  value={currentQuestion.difficulty}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded w-full"
                >
                  <option value="Easy">Easy</option>
                  <option value="Hard">Hard</option>
                </select>
              </div>
            </form>
            <div className="flex justify-end mt-4">
              <button 
                onClick={handleCloseModal} 
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                Close
              </button>
              <button 
                onClick={handleSaveChanges} 
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionTable;
