import React from 'react';
import Table from '../../components/common-ui/Table/table';
import { updateCheckedStatus } from '../../services/responses.service';

const ResponseTable = ({ questionsData, usersData, responsesData, fetchDataFromApi }) => {

  const handleChecked = async (responseId) => {
    try {
      await updateCheckedStatus(responseId);
      await fetchDataFromApi(); 
    } catch (error) {
      console.error('Error updating checked status:', error);
    }
  };

  const createTableRows = () => {
    return responsesData.map(response => {
      const user = usersData.find(user => user._id === response.userId);
      const userName = user ? user.username : 'N/A';
      const question = questionsData.find(q => q._id === response.questionId);

      return [
        userName, 
        question ? question.question : 'N/A',
        question ? question.subject : 'N/A',
        question ? question.questionType : 'N/A',
        question ? question.difficulty : 'N/A',
        response.answer,
        response.checked === 0 ? (
          <button
            onClick={() => handleChecked(response._id)}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Check
          </button>
        ) : (
          'Checked'
        )
      ];
    });
  };

  
  const headers = [
    'User Name',
    'Question',
    'Subject',
    'Question Type',
    'Difficulty',
    'Answer',
    'Status' 
  ];

  return (
    <div>
      <Table headers={headers} data={createTableRows()} />
    </div>
  );
};

export default ResponseTable;
