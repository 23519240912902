// questions.service.js
const apiUrl = (`${process.env.REACT_APP_BASE_URL}/yuni/questions`);
;

export const fetchQuestions = async () => {
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch questions');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw error;
  }
};

export const updateQuestion = async (questionId, updatedData) => {
  const updateUrl = `${process.env.REACT_APP_BASE_URL}/yuni/updatequestions/${questionId}`;

  try {
    const response = await fetch(updateUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw new Error('Failed to update question');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating question:', error);
    throw error;
  }
};
